<template>
	<div
		:class="[
			'c-long-read z-20',
			'bg-opacity-90 duration-500 ease-smooth-out',
			'<1024:-mx-layout-margin <1024:px-layout-margin <1024:pt-md',
			{ 'bg-white': isExpanded },
		]"
	>
		<LongReadController>
			<template #default="{ data, actions }">
				<!-- Desktop -->
				<div
					:class="[
						'c-long-read__desktop',
						'hidden pointer-events-auto',
						'>=1024:grid gap-y-lg',
					]"
				>
					<h2
						:class="[
							'sticky top-0',
							'text-aside-title uppercase',
							'font-darker-grotesque font-extrabold',
						]"
						v-text="'Sidens indhold'"
					></h2>

					<div class="relative">
						<div
							ref="desktopList"
							:class="[
								'c-long-read__desktop-inner',
								'overflow-y-scroll max-h-full',
								'pl-26 -ml-26 pb-4',
							]"
							@scroll="onInnerScroll"
						>
							<ul ref="desktopListInner" class="space-y-md">
								<li
									v-for="(target, index) in data.targets"
									:key="`item-${index}`"
								>
									<a
										:href="`#${target.id}`"
										:class="[
											'c-long-read__target relative',
											'font-darker-grotesque font-medium text-aside',
											'block pr-8',

											{
												'c-long-read__target--active font-bold pr-0':
													data.activeTarget.id ===
													target.id,
											},
										]"
										:aria-current="
											data.activeTarget.id === target.id
										"
										@click.prevent="
											() =>
												actions.scrollToTarget &&
												actions.scrollToTarget(target)
										"
										v-text="target.title"
									></a>
								</li>
							</ul>
						</div>

						<div
							:class="[
								'c-long-read__gradient',
								'c-long-read__gradient--top',

								{
									'c-long-read__gradient--active':
										canScrollUp,
								},
							]"
						></div>
						<div
							:class="[
								'c-long-read__gradient',
								'c-long-read__gradient--bottom',

								{
									'c-long-read__gradient--active':
										canScrollDown,
								},
							]"
						></div>
					</div>
				</div>

				<!-- Mobile -->
				<BaseInputDropdown
					id="c-long-read__mobile"
					ref="mobileList"
					class=">=1024:hidden pointer-events-auto"
					placeholder="Sidens Indholds"
					:value="`option-${
						data.activeTarget && data.activeTarget.id
					}`"
					:options="
						data.targets.map((item) => ({
							target: item,
							label: item.title,
							value: `option-${item.id}`,
						}))
					"
					simple
					@expand="onExpand"
				>
					<template #option="{ label, target }">
						<a
							:href="`#${target.id}`"
							:class="[
								'c-long-read__mobile-option',
								'relative block',
								'w-full text-left pr-20 py-4 transform',
								'text-longread-mobile text-opacity-100 font-medium',
								'duration-300 ease-smooth-out',

								{
									'c-long-read__mobile-option--active font-semibold translate-x-20':
										target.id === data.activeTarget.id,
								},
							]"
							@click.prevent="
								() => actions.scrollToTarget(target)
							"
							v-text="label"
						></a>
					</template>
				</BaseInputDropdown>
			</template>
		</LongReadController>
	</div>
</template>

<script>
import { LongReadController } from '~/citi-baseline/components/LongRead';

export default {
	name: 'LongRead',
	components: { LongReadController },

	data() {
		return {
			isExpanded: false,
			canScrollUp: false,
			canScrollDown: false,
		};
	},

	mounted() {
		window.addEventListener('scroll', this.onScroll);
		window.addEventListener('resize', this.onInnerScroll.bind(this, null));
		this.onInnerScroll();
	},

	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll);
		window.removeEventListener(
			'resize',
			this.onInnerScroll.bind(this, null)
		);
	},

	methods: {
		onScroll() {
			if (this.$refs.mobileList) {
				this.$refs.mobileList.toggle(false);
			}
		},

		onInnerScroll(event) {
			if (this.$refs.desktopList && this.$refs.desktopListInner) {
				const { desktopList, desktopListInner } = this.$refs;
				const { height: oh } = desktopList.getBoundingClientRect();
				const { height: ih } = desktopListInner.getBoundingClientRect();
				const top = event?.target?.scrollTop ?? 0;

				this.canScrollUp = top > 0;
				this.canScrollDown = top < ih - oh;
			}
		},

		onExpand(value) {
			this.isExpanded = value;
		},
	},
};
</script>

<style lang="postcss">
@screen <1024 {
	.c-long-read {
		height: calc(100vh - var(--theme-spacing-site-header-compact));
	}
}

.c-long-read__desktop {
	grid-template-rows: 24px minmax(0, 1fr);
	max-height: calc(
		100vh - var(--theme-spacing-6xl) - var(--theme-spacing-2xl)
	);
}

.c-long-read__target:before {
	@apply absolute -left-20 top-1/2;
	@apply transform -translate-x-1/2 -translate-y-1/2 opacity-0;
	@apply w-8 h-8 rounded-full bg-primary-dark;
	@apply duration-500 ease-smooth-out;
	content: '';
}

.c-long-read__target--active:before {
	@apply opacity-100;
}

.c-long-read__mobile-option:before {
	@apply absolute top-1/2 -left-20 transform -translate-y-1/2;
	@apply w-8 h-8 rounded-full bg-primary opacity-0;
	@apply duration-300 ease-smooth-out;
	content: '';
}

.c-long-read__mobile-option--active:before {
	@apply opacity-100;
}

.c-long-read__gradient {
	@apply absolute left-0 w-full h-64 transform;
	@apply opacity-0 duration-200 ease-smooth-out;
	@apply pointer-events-none;

	background: linear-gradient(
		180deg,
		rgba(255, 255, 255, 1) 0%,
		rgba(255, 255, 255, 0) 100%
	);
}

.c-long-read__gradient--active {
	@apply opacity-100;
}

.c-long-read__gradient--top {
	@apply top-0;
}

.c-long-read__gradient--bottom {
	@apply bottom-0 rotate-180;
}
</style>
