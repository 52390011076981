import { camelCase, upperFirst } from 'lodash';

const BLOCKS_BY_SOLUTION_AND_ALIAS = {
	// Plop: block-list - add solution
	subsite: {
		// Plop: block-list - add subsite block
	},
	main: {
		// Plop: block-list - add main block
		BlockExternalscript: () =>
			import(
				/* webpackChunkName: "main__blockListExternalScript" */ '~/components/main/BlockList/blocks/BlockListExternalScriptCustom.vue'
			),
		BlockIframe: () =>
			import(
				/* webpackChunkName: "main__blockListIframe" */ '~/components/main/BlockList/blocks/BlockListIframeCustom.vue'
			),
	},
	shared: {
		BlockShortcuts: () =>
			import(
				/* webpackChunkName: "shared__blockListShortcuts" */ '~/components/shared/BlockList/blocks/BlockListShortcutsCustom.vue'
			),
		BlockSpotboxContainer: () =>
			import(
				/* webpackChunkName: "shared__blockListSpotBox" */ '~/components/shared/BlockList/blocks/BlockListSpotBoxCustom.vue'
			),
		BlockVisualList: () =>
			import(
				/* webpackChunkName: "shared__blockListVisualShortcuts" */ '~/components/shared/BlockList/blocks/BlockListVisualShortcutsCustom.vue'
			),
		BlockImages: () =>
			import(
				/* webpackChunkName: "shared__blockListImages" */ '~/components/shared/BlockList/blocks/BlockListImagesCustom.vue'
			),
		BlockImagegallery: () =>
			import(
				/* webpackChunkName: "shared__blockListImageGallery" */ '~/components/shared/BlockList/blocks/BlockListImageGalleryCustom.vue'
			),
		BlockVideo: () =>
			import(
				/* webpackChunkName: "shared__blockListVideo" */ '~/components/shared/BlockList/blocks/BlockListVideoCustom.vue'
			),
		BlockNewslist: () =>
			import(
				/* webpackChunkName: "shared__blockListNewsList" */ '~/components/shared/BlockList/blocks/BlockListNewsListCustom.vue'
			),
		BlockBorgerdk: () =>
			import(
				/* webpackChunkName: "shared__blockListBorgerdk" */ '~/components/shared/BlockList/blocks/BlockListBorgerDkCustom.vue'
			),
		BlockAccordion: () =>
			import(
				/* webpackChunkName: "shared__blockListAccordion" */ '~/components/shared/BlockList/blocks/BlockListAccordionCustom.vue'
			),
		BlockSelfservice: () =>
			import(
				/* webpackChunkName: "shared__blockListSelfservice" */ '~/components/shared/BlockList/blocks/BlockListSelfServiceCustom.vue'
			),
		selfservice: () =>
			import(
				/* webpackChunkName: "shared__blockListSelfservice" */ '~/components/shared/BlockList/blocks/BlockListSelfServiceCustom.vue'
			),
		BlockRichtexteditor: () =>
			import(
				/* webpackChunkName: "shared__blockListRichTextEditor" */ '~/components/shared/BlockList/blocks/BlockListRichTextEditorCustom.vue'
			),
		richtexteditor: () =>
			import(
				/* webpackChunkName: "shared__blockListRichTextEditor" */ '~/components/shared/BlockList/blocks/BlockListRichTextEditorCustom.vue'
			),
		BlockCtabutton: () =>
			import(
				/* webpackChunkName: "shared__blockListCtaButton" */ '~/components/shared/BlockList/blocks/BlockListCtaButtonCustom.vue'
			),
		BlockCtabox: () =>
			import(
				/* webpackChunkName: "shared__blockListCtabox" */ '~/components/shared/BlockList/blocks/BlockListCtaBoxCustom.vue'
			),
		BlockDocumentlist: () =>
			import(
				/* webpackChunkName: "shared__blockListDocumentList" */ '~/components/shared/BlockList/blocks/BlockListDocumentListCustom.vue'
			),
		BlockFactbox: () =>
			import(
				/* webpackChunkName: "shared__blockListFactbox" */ '~/components/shared/BlockList/blocks/BlockListFactBoxCustom.vue'
			),
		BlockQuote: () =>
			import(
				/* webpackChunkName: "shared__blockListQuote" */ '~/components/shared/BlockList/blocks/BlockListQuoteCustom.vue'
			),
		BlockForm: () =>
			import(
				/* webpackChunkName: "shared__blockListForm" */ '~/components/shared/BlockList/blocks/BlockListFormCustom.vue'
			),
		BlockContactgroup: () =>
			import(
				/* webpackChunkName: "shared__blockListContactGroup" */ '~/components/shared/BlockList/blocks/BlockListContactGroupCustom.vue'
			),
		BlockContactperson: () =>
			import(
				/* webpackChunkName: "shared__blockListContactPerson" */ '~/components/shared/BlockList/blocks/BlockListContactPersonCustom.vue'
			),
		BlockMap: () =>
			import(
				/* webpackChunkName: "shared__blockListMap" */ '~/components/shared/BlockList/blocks/BlockListMapCustom.vue'
			),
		BlockLinklist: () =>
			import(
				/* webpackChunkName: "shared__blockListLinkList" */ '~/components/shared/BlockList/blocks/BlockListLinkListCustom.vue'
			),
		BlockStructureddata: () =>
			import(
				/* webpackChunkName: "shared__blockListStructuredData" */ '~/components/shared/BlockList/blocks/BlockListStructuredDataCustom.vue'
			),
		BlockTextmedia: () =>
			import(
				/* webpackChunkName: "shared__blockListTextMedia" */ '~/components/shared/BlockList/blocks/BlockListTextMediaCustom.vue'
			),
	},
};

export const getBlockNameFromAlias = (alias) =>
	`BlockList${upperFirst(camelCase(alias))}`;

export const getBlocksBySolution = (solution) =>
	Object.entries(BLOCKS_BY_SOLUTION_AND_ALIAS[solution] ?? {}).reduce(
		(acc, [alias, component]) => ({
			...acc,

			[getBlockNameFromAlias(alias)]: component,
		}),
		{}
	);
