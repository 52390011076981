var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-related-pages"},[(_vm.title && _vm.title.length)?_c('BaseH2',{staticClass:"mb-xl",domProps:{"textContent":_vm._s(_vm.title)}}):_vm._e(),_vm._v(" "),_c('div',{class:[
			'grid gap-x-layout-gutter gap-y-md grid-cols-1',
			'>=768:grid-cols-2 >=1024:grid-cols-3 >=1200:grid-cols-4',
		],style:({
			'grid-auto-rows': `1fr`,
		})},_vm._l((_vm.computedLinks),function(page,index){return _c('NuxtLinkExt',{key:`page-${index}`,staticClass:"px-6",style:(`grid-row-start: ${Math.ceil((index + 1) / _vm.columns)};`),attrs:{"to":page.url,"target":page.target}},[_c('BaseButton',{staticClass:"h-full",attrs:{"tag":"div","grey":_vm.grey,"large":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',{domProps:{"textContent":_vm._s(page.name)}})]},proxy:true},{key:"icon",fn:function(){return [_c('SvgArrow',{class:{
							'transform -rotate-45': _vm.isExternal(page.url),
						}})]},proxy:true}],null,true)})],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }