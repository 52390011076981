<template>
	<div class="c-long-read-context">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'LongReadContext',
};
</script>
