var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
		'c-long-read z-20',
		'bg-opacity-90 duration-500 ease-smooth-out',
		'<1024:-mx-layout-margin <1024:px-layout-margin <1024:pt-md',
		{ 'bg-white': _vm.isExpanded },
	]},[_c('LongReadController',{scopedSlots:_vm._u([{key:"default",fn:function({ data, actions }){return [_c('div',{class:[
					'c-long-read__desktop',
					'hidden pointer-events-auto',
					'>=1024:grid gap-y-lg',
				]},[_c('h2',{class:[
						'sticky top-0',
						'text-aside-title uppercase',
						'font-darker-grotesque font-extrabold',
					],domProps:{"textContent":_vm._s('Sidens indhold')}}),_vm._v(" "),_c('div',{staticClass:"relative"},[_c('div',{ref:"desktopList",class:[
							'c-long-read__desktop-inner',
							'overflow-y-scroll max-h-full',
							'pl-26 -ml-26 pb-4',
						],on:{"scroll":_vm.onInnerScroll}},[_c('ul',{ref:"desktopListInner",staticClass:"space-y-md"},_vm._l((data.targets),function(target,index){return _c('li',{key:`item-${index}`},[_c('a',{class:[
										'c-long-read__target relative',
										'font-darker-grotesque font-medium text-aside',
										'block pr-8',

										{
											'c-long-read__target--active font-bold pr-0':
												data.activeTarget.id ===
												target.id,
										},
									],attrs:{"href":`#${target.id}`,"aria-current":data.activeTarget.id === target.id},domProps:{"textContent":_vm._s(target.title)},on:{"click":function($event){$event.preventDefault();return (() =>
											actions.scrollToTarget &&
											actions.scrollToTarget(target)).apply(null, arguments)}}})])}),0)]),_vm._v(" "),_c('div',{class:[
							'c-long-read__gradient',
							'c-long-read__gradient--top',

							{
								'c-long-read__gradient--active':
									_vm.canScrollUp,
							},
						]}),_vm._v(" "),_c('div',{class:[
							'c-long-read__gradient',
							'c-long-read__gradient--bottom',

							{
								'c-long-read__gradient--active':
									_vm.canScrollDown,
							},
						]})])]),_vm._v(" "),_c('BaseInputDropdown',{ref:"mobileList",staticClass:">=1024:hidden pointer-events-auto",attrs:{"id":"c-long-read__mobile","placeholder":"Sidens Indholds","value":`option-${
					data.activeTarget && data.activeTarget.id
				}`,"options":data.targets.map((item) => ({
						target: item,
						label: item.title,
						value: `option-${item.id}`,
					})),"simple":""},on:{"expand":_vm.onExpand},scopedSlots:_vm._u([{key:"option",fn:function({ label, target }){return [_c('a',{class:[
							'c-long-read__mobile-option',
							'relative block',
							'w-full text-left pr-20 py-4 transform',
							'text-longread-mobile text-opacity-100 font-medium',
							'duration-300 ease-smooth-out',

							{
								'c-long-read__mobile-option--active font-semibold translate-x-20':
									target.id === data.activeTarget.id,
							},
						],attrs:{"href":`#${target.id}`},domProps:{"textContent":_vm._s(label)},on:{"click":function($event){$event.preventDefault();return (() => actions.scrollToTarget(target)).apply(null, arguments)}}})]}}],null,true)})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }