<template>
	<div class="c-related-pages">
		<BaseH2 v-if="title && title.length" class="mb-xl" v-text="title" />

		<div
			:class="[
				'grid gap-x-layout-gutter gap-y-md grid-cols-1',
				'>=768:grid-cols-2 >=1024:grid-cols-3 >=1200:grid-cols-4',
			]"
			:style="{
				'grid-auto-rows': `1fr`,
			}"
		>
			<NuxtLinkExt
				v-for="(page, index) in computedLinks"
				:key="`page-${index}`"
				class="px-6"
				:to="page.url"
				:target="page.target"
				:style="`grid-row-start: ${Math.ceil((index + 1) / columns)};`"
			>
				<BaseButton tag="div" class="h-full" :grey="grey" large>
					<template #default>
						<span v-text="page.name"></span>
					</template>

					<template #icon>
						<SvgArrow
							:class="{
								'transform -rotate-45': isExternal(page.url),
							}"
						/>
					</template>
				</BaseButton>
			</NuxtLinkExt>
		</div>
	</div>
</template>

<script>
import SvgArrow from '~/assets/svgs/icon-arrow.svg?inline';

export default {
	name: 'RelatedPages',
	components: { SvgArrow },

	props: {
		title: String,
		links: Array,
		grey: Boolean,
	},

	data() {
		return {
			columns: Math.min(4, this.links?.length || 0),
		};
	},

	computed: {
		computedLinks() {
			const links = this.links?.filter?.((link) => link?.url) || [];
			return links;
		},
	},

	mounted() {
		window.addEventListener('resize', this.onResize);
		this.onResize();
	},

	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},

	methods: {
		onResize() {
			const { innerWidth } = window;

			this.columns = 1;
			innerWidth >= 768 && (this.columns = 2);
			innerWidth >= 1024 && (this.columns = 3);
			innerWidth >= 1200 && (this.columns = 4);
		},

		isExternal(url) {
			if (url) {
				if (
					['http://', 'https://', 'ftp://'].some(
						(str) => url.indexOf(str) === 0
					)
				) {
					return true;
				}

				const splitOut = url.split('/');
				if (splitOut[0].indexOf('.') >= 0) {
					return true;
				}
			}

			return false;
		},
	},
};
</script>
